import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import English from "../src/Languages/English.json";
import Gujarati from "../src/Languages/Gujarati.json";
// import Spanish from "../src/languages/Spanish.json";
// import Mandarin from "../src/languages/Mandarin.json";
// import German from "../src/languages/German.json";
// import Dutch from "../src/languages/Dutch.json";


i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
      translations: English,
      },
      gu: {
        translation: Gujarati,
      },
      
    },
    lng: localStorage.getItem("lng") || "gu", 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
    parseMissingKeyHandler: (key) => {
      return key;
    },
  });

export default i18next;
