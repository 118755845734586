import React, { lazy } from "react";
import { Routing } from "./constants";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MainLayout } from "../Layout/MainLayout";


const BusWorking = lazy(() => import("../Home/BusWorking"));
const ReservationList = lazy(() => import("../Reservations/ReservationList"));
const ViewReservation = lazy(() => import("../Reservations/ViewReservation"));
const EditReservation = lazy(() => import("../Reservations/EditReservation"));
const EditAddress = lazy(() => import("../Settings/Address/EditAddress"));
const EditBus = lazy(() => import("../Settings/Bus/EditBus"));
const AddBus = lazy(() => import("../Settings/Bus/AddBus"));
const AddCity = lazy(() => import("../Settings/City/AddCity"));
const AddAddress = lazy(() => import("../Settings/Address/AddAddress"));
const EditCity = lazy(() => import("../Settings/City/EditCity"));
const Setting = lazy(() => import("../Settings/settings"));
const NewBooking = lazy(() => import("../NewBooking/NewBooking"));
const Login = lazy(() => import("../Login/LoginRoute"));
const RouteDetail = lazy(() => import("../RouteDetails/RouteDetail"));
const BusManagement = lazy(() => import("../BusManagement/BusManagement"));

const routes = [
    {
      id: 0,
      path: Routing.BusWorking,
      component: BusWorking,
      isPrivateRoute: true,
    },
    {
      id: 1,
      path: Routing.Reservation,
      component: ReservationList,
      isPrivateRoute: true,
    },
    {
      id: 2,
      path: Routing.ViewReservation,
      component: ViewReservation,
      isPrivateRoute: true,
    },
    {
      id: 3,
      path: Routing.EditReservation,
      component: EditReservation,
      isPrivateRoute: true,
    },
    {
      id: 4,
      path: Routing.EditAddress,
      component: EditAddress,
      isPrivateRoute: true,
    },
    {
      id: 5,
      path: Routing.EditCity,
      component: EditCity,
      isPrivateRoute: true,
    },
    {
      id: 6,
      path: Routing.EditBus,
      component: EditBus,
      isPrivateRoute: true,
    },
    {
      id: 7,
      path: Routing.Setting,
      component: Setting,
      isPrivateRoute: true,
    },
    {
      id: 8,
      path: Routing.NewBooking,
      component: NewBooking,
      isPrivateRoute: true,
    },
    {
      id: 9,
      path: Routing.AddBus,
      component: AddBus,
      isPrivateRoute: true,
    },
    {
      id: 10,
      path: Routing.AddCity,
      component: AddCity,
      isPrivateRoute: true,
    },
    {
      id: 11,
      path: Routing.AddAddress,
      component: AddAddress,
      isPrivateRoute: true,
    },
    {
      id: 12,
      path: Routing.BusManagement,
      component: BusManagement,
      isPrivateRoute: true,
    },
    {
      id: 13,
      path: Routing.RouteDetail,
      component: RouteDetail,
      isPrivateRoute: true,
    },
    {
      id: 14,
      path: Routing.BoardingPoint,
      component: BusWorking,
      isPrivateRoute: true,
    },
    {
      id: 15,
      path: Routing.DropingPoint,
      component: BusWorking,
      isPrivateRoute: true,
    },
    {
      id: 16,
      path: Routing.Gallary,
      component: BusWorking,
      isPrivateRoute: true,
    },
    {
      id: 17,
      path: Routing.ChartOpen,
      component: BusWorking,
      isPrivateRoute: true,
    },
    {
      id: 18,
      path: Routing.Login,
      component: Login,
      isPrivateRoute: false,
    },
   
]

const AppRouting = () => {


    return (
        <Router >
        <ToastContainer key={1} className="toaster" theme="dark" />
       
  
        <Routes>
        {routes
          .map((route, index) => {
            return (
              <React.Fragment key={route.id}>
                {route?.isPrivateRoute ? (
                  <Route
                    path={route.path}
                    element={
                      <MainLayout>
                        <route.component />
                      </MainLayout>
                    }
                  />) : (
                    <Route
                      path={route.path}
                      element={<route.component />}
                    />) }
                
                </React.Fragment> 
              )
              })
            }
        </Routes>
        </Router>
    )
}

export default AppRouting;